interface BrandColors {
    primary: string;
    secondary: string;
    accent: string;
    neutralLight: string;
    support: string;
    error: string;
}

const brandColors: BrandColors = {
    primary: "#1A4D8F",      // Deep Ocean Blue
    secondary: "#5A99D3",    // Soft Sky Blue
    accent: "#00CFFF",       // Bright Cyan
    neutralLight: "#E6F4F9", // Light Ice Blue
    support: "#3A7E8F",      // Muted Teal
    error: "#FF6B6B",        // Coral Red
};

export default brandColors;