// src/components/ContactUs.tsx

import React, { useState } from "react";
import {
    Box,
    Heading,
    Input,
    Textarea,
    Button,
    Text,
} from "@chakra-ui/react";
import brandColors from "../utils/brandColors";

const ContactUs: React.FC = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = () => {
        // Basic form validation
        if (!firstName || !lastName || !phone || !email || !message) {
            setError("Please fill in all fields.");
            return;
        }

        // Clear any existing errors
        setError("");

        // Instead of calling an API, just log the values for now
        console.log({
            firstName,
            lastName,
            phone,
            email,
            message,
        });

        // Show a simple "Thank you" screen
        setSubmitted(true);
    };

    if (submitted) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minH="100vh"
                bg={brandColors.neutralLight}
                px={4}
            >
                <Box
                    w="full"
                    maxW="600px"
                    bg="white"
                    borderRadius="md"
                    boxShadow="lg"
                    p={[6, 8]}
                    textAlign="center"
                >
                    <Heading as="h2" size="lg" mb={4} color={brandColors.primary}>
                        Thank You
                    </Heading>
                    <Text mb={6}>
                        Thank you for submitting your information. We will get back to you soon.
                    </Text>
                    <Button
                        bg={brandColors.accent}
                        color={brandColors.neutralLight}
                        onClick={() => setSubmitted(false)}
                        _hover={{ bg: brandColors.primary }}
                    >
                        Submit Another Response
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minH="100vh"
            bg={brandColors.neutralLight}
            px={4}
        >
            <Box
                w="full"
                maxW="600px"
                bg="white"
                borderRadius="md"
                boxShadow="lg"
                p={[6, 8]}
                textAlign="center"
            >
                <Heading as="h2" size="lg" mb={6} color={brandColors.primary}>
                    Contact Us
                </Heading>

                {error && (
                    <Text color={brandColors.error} mb={4}>
                        {error}
                    </Text>
                )}

                <Input
                    placeholder="First Name"
                    mb={4}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    focusBorderColor={brandColors.accent}
                    variant="filled"
                    _focus={{ bg: "white" }}
                />

                <Input
                    placeholder="Last Name"
                    mb={4}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    focusBorderColor={brandColors.accent}
                    variant="filled"
                    _focus={{ bg: "white" }}
                />

                <Input
                    placeholder="Phone Number"
                    mb={4}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    focusBorderColor={brandColors.accent}
                    variant="filled"
                    _focus={{ bg: "white" }}
                />

                <Input
                    placeholder="Your Email"
                    type="email"
                    mb={4}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    focusBorderColor={brandColors.accent}
                    variant="filled"
                    _focus={{ bg: "white" }}
                />

                <Textarea
                    placeholder="Your Message"
                    mb={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    focusBorderColor={brandColors.accent}
                    variant="filled"
                    _focus={{ bg: "white" }}
                    resize="vertical"
                />

                <Button
                    width="full"
                    bg={brandColors.accent}
                    color={brandColors.neutralLight}
                    onClick={handleSubmit}
                    _hover={{ bg: brandColors.primary }}
                    size="lg"
                    fontWeight="bold"
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default ContactUs;