// src/pages/RiskManagementLanding.tsx

import React from "react";
import {
    Box,
    Heading,
    Text,
    Button,
    Avatar,
    SimpleGrid,
    Flex,
    useBreakpointValue,
} from "@chakra-ui/react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import brandColors from "../utils/brandColors";
import {useNavigate} from "react-router-dom";
import {ResponsiveValue} from "@chakra-ui/icons";

// Register chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    // Dummy data for the Line Chart
    const lineChartData = {
        labels: ["Project A", "Project B", "Project C", "Project D", "Project E"],
        datasets: [
            {
                label: "Cost Overruns ($M)",
                data: [2.3, 1.8, 3.4, 2.9, 4.2],
                fill: false,
                borderColor: brandColors.accent,
                backgroundColor: brandColors.accent,
                tension: 0.3,
            },
        ],
    };

    const lineChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    // Dummy data for the Pie Chart
    const pieChartData = {
        labels: ["Low Risk", "Medium Risk", "High Risk"],
        datasets: [
            {
                data: [45, 35, 20],
                backgroundColor: [
                    brandColors.secondary,
                    brandColors.support,
                    brandColors.error,
                ],
            },
        ],
    };

    const pieChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom" as const,
            },
        },
    };

    // Use a responsive layout for hero section
    const heroFlexDirection: ResponsiveValue<"column" | "row"> =
        useBreakpointValue({ base: "column", md: "row" }) ?? "column";
    const heroTextAlign: ResponsiveValue<"left" | "center" | "right"> =
        useBreakpointValue({ base: "center", md: "left" }) ?? "center";

    return (
        <Box
            display="flex"
            flexDirection="column"
            minH="100vh"
            bg={brandColors.neutralLight}
            px={4}
            py={8}
        >
            {/* Hero Section */}
            <Box
                display="flex"
                flexDirection={heroFlexDirection}
                justifyContent="space-between"
                alignItems="center"
                bg="white"
                borderRadius="md"
                boxShadow="lg"
                p={[6, 8]}
                mb={8}
            >
                <Box flex="1" textAlign={heroTextAlign}>
                    <Heading as="h1" size="xl" mb={4} color={brandColors.primary}>
                        Risk Management for Infrastructure & Capital Projects
                    </Heading>
                    <Text fontSize="lg" mb={6}>
                        Providing comprehensive solutions to control project costs and
                        timelines through industry-leading standards and risk analysis
                        techniques.
                    </Text>
                    <Button
                        size="lg"
                        bg={brandColors.accent}
                        color={brandColors.neutralLight}
                        _hover={{ bg: brandColors.primary }}
                        onClick={() => navigate("/contact-us")}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>

            {/* Features Section */}
            <Box
                bg="white"
                borderRadius="md"
                boxShadow="lg"
                p={[6, 8]}
                mb={8}
                textAlign="center"
            >
                <Heading as="h2" size="lg" mb={4} color={brandColors.primary}>
                    Our Services
                </Heading>
                <Text mb={2}>
                    • Conducting Quantitative Cost Risk Analysis (QCRA) and Quantitative
                    Schedule Risk Analysis (QSRA) for high-budget projects.
                </Text>
                <Text mb={2}>
                    • Developing Risk Management Plans, Frameworks, and Handbooks in
                    accordance with PMI and ISO standards.
                </Text>
                <Text>
                    • Facilitating interdisciplinary risk discussions and creating
                    actionable mitigation strategies.
                </Text>
            </Box>

            {/* Graphs Section */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={8}>
                {/* Cost Overrun Line Chart */}
                <Box
                    bg="white"
                    borderRadius="md"
                    boxShadow="lg"
                    p={[6, 8]}
                    textAlign="center"
                >
                    <Heading as="h3" size="md" mb={4} color={brandColors.primary}>
                        Cost Overruns Analysis
                    </Heading>
                    <Box height="300px">
                        <Line data={lineChartData} options={lineChartOptions} />
                    </Box>
                </Box>

                {/* Risk Distribution Pie Chart */}
                <Box
                    bg="white"
                    borderRadius="md"
                    boxShadow="lg"
                    p={[6, 8]}
                    textAlign="center"
                >
                    <Heading as="h3" size="md" mb={4} color={brandColors.primary}>
                        Risk Distribution
                    </Heading>
                    <Box height="300px">
                        <Pie data={pieChartData} options={pieChartOptions} />
                    </Box>
                </Box>
            </SimpleGrid>

            {/* Testimonials Section */}
            <Box
                bg="white"
                borderRadius="md"
                boxShadow="lg"
                p={[6, 8]}
                mb={8}
                textAlign="center"
            >
                <Heading as="h2" size="lg" mb={6} color={brandColors.primary}>
                    What Our Clients Say
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                    {/* Testimonial 1 */}
                    <Box
                        boxShadow="md"
                        borderRadius="md"
                        p={6}
                        bg={brandColors.neutralLight}
                    >
                        <Avatar
                            size="xl"
                            mb={4}
                            src="https://i.pravatar.cc/150?img=8"
                            name="John Doe"
                            mx="auto"
                        />
                        <Heading as="h4" size="md" mb={2}>
                            Omar Bisher
                        </Heading>
                        <Text fontSize="sm">
                            "Their QCRA/QSRA services saved us millions. Highly recommended!"
                        </Text>
                    </Box>

                    {/* Testimonial 2 */}
                    <Box
                        boxShadow="md"
                        borderRadius="md"
                        p={6}
                        bg={brandColors.neutralLight}
                    >
                        <Avatar
                            size="xl"
                            mb={4}
                            src="https://i.pravatar.cc/150?img=12"
                            name="Jane Smith"
                            mx="auto"
                        />
                        <Heading as="h4" size="md" mb={2}>
                            James Brown
                        </Heading>
                        <Text fontSize="sm">
                            "Their risk management frameworks streamlined our entire process!"
                        </Text>
                    </Box>

                    {/* Testimonial 3 */}
                    <Box
                        boxShadow="md"
                        borderRadius="md"
                        p={6}
                        bg={brandColors.neutralLight}
                    >
                        <Avatar
                            size="xl"
                            mb={4}
                            src="https://i.pravatar.cc/150?img=33"
                            name="Robert Brown"
                            mx="auto"
                        />
                        <Heading as="h4" size="md" mb={2}>
                            Robert Brown
                        </Heading>
                        <Text fontSize="sm">
                            "Fantastic facilitation of risk sessions and actionable mitigation
                            strategies!"
                        </Text>
                    </Box>
                </SimpleGrid>
            </Box>

            {/* Call-To-Action */}
            <Flex justifyContent="center">
                <Button
                    size="lg"
                    bg={brandColors.accent}
                    color={brandColors.neutralLight}
                    _hover={{ bg: brandColors.primary }}
                    onClick={() => navigate("/contact-us")}
                >
                    Get Started
                </Button>
            </Flex>
        </Box>
    );
};

export default LandingPage;