import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import ContactUs from "./pages/ContactUs";

function App() {
    return (
        <ChakraProvider>
            <Router>
                <Routes>
                    {/* Dashboard acts as a wrapper for nested routes */}
                    <Route path="/" element={<Dashboard />}>
                        <Route index element={<LandingPage />} />
                        <Route path="contact-us" element={<ContactUs />} />
                    </Route>
                </Routes>
            </Router>
        </ChakraProvider>
    );
}

export default App;